<template>
	<div>
		<div class="mainCode">
			<div style="
          font-size: 20px;
          color: #999;
          margin-bottom: 10px;
          padding-left: 30px;
          padding-top: 20px;
          display: flex;
          align-items: center;
        ">
				<div style="
            display: flex;
            font-size: 26px;
            margin-right: 20px;
            color: #333;
          ">
					<img style="width: 32px; height: 32px; margin-right: 10px" src="@/assets/wx.png" alt="">微信支付
				</div>
				<div style="width: 1px; height: 24px; background: #999; margin-right: 20px" />
				<span>我的收银台</span>
			</div>
			<div class="mainCode-h1">
				<div>



					<b>订单支付</b> <text style="margin-left: 15rpx;"> 收款方：{{ companyName }}</text>
				</div>
				<div class="money">{{ amount }}</div>
			</div>
			<div style="display: flex; align-items: center; justify-content: center">
				<div style="margin-right: 50px">
					<div style="text-align: center; padding-top: 10px; color: #666">
						扫一扫付款（元）
					</div>
					<div style="
              text-align: center;
              line-height: 40px;
              color: #ff6600;
              font-size: 24px;
            ">
						{{ amount }}
					</div>
					<div class="qrdiv">
						<div id="qrcodr" ref="qrcodr" />
						<p class="payTxt">打开手机微信<br>扫一扫继续付款</p>
					</div>
				</div>
				<img src="https://gw.alipayobjects.com/mdn/rms_9e4c39/afts/img/A*KlAPTbO_zAUAAAAAAAAAAAAAARQnAQ"
					style="width: 150px" alt="">
			</div>
		</div>

	</div>
</template>

<script>
import QRCode from 'qrcodejs2'
import { getPaymentStatus } from "@/utils/api/myApi/index"
export default {
	name: 'WeChatPayPage',

	data() {
		return {
			zdInfo: {},
			url: '',

			qrCode: '',
			amount: 0.1,
			type: '',
			orderId: '',
			companyName: '上海发易发网络科技有限公司',
			payType: '',
			isWebSitePay: false,
			buySourceType: '',
			codeUrl: "weixin://wxpay/bizpayurl?pr=dH6Wtnczz",
			timer: null,
			num: 0
		}
	},

	mounted() {
		if (this.timer) {
			clearInterval(this.timer) // 关闭
		}
		this.orderId = this.$route.query.outTradeNo;
		this.codeUrl = this.$route.query.codeUrl;
		this.amount = this.$route.query.couponNum
		this.qrcodeClick(this.codeUrl)
		if (!this.timer) {
			this.timer = setInterval(() => {
				// 每隔3秒调用一次方法
				this.getOrderstate();
			}, 3000);
		}
	},
	watch: {
		num(newValue, oldValue) {
			if (this.num >= 500) {
				clearInterval(this.timer)
				this.$confirm('长时间未付款，订单已自动关闭！', '提示', {
					confirmButtonText: '确定',
					showCancelButton: false,
					type: 'warning'
				}).then(() => {
					this.$router.push('/myIndex/myOrder?actMyNum=0')
				})

			}
		},
	},
	methods: {
		wxPay() {
			wxPayObj({
				total_amount: this.amount
			}).then((res) => { })
		},
		qrcodeClick(val) {

			const _this = this
			// 获取文档中 ID="qrcodr" 的第一个元素:
			var qrcodr = document.querySelector("#qrcodr")
			setTimeout(() => {
				// this.$nextTick(()=>{
				_this.qrCode = new QRCode(qrcodr, {
					width: 150, // 二维码的宽度
					height: 150, // 二维码的高度
					text: val, // 调用weixincode()方法时后台传的code
					colorDark: '#000', // 二维码颜色
					colorLight: '#fff', // 二维码底色
					correctLevel: QRCode.CorrectLevel.H

				})
				// })
				// document.getElementById("qrcode").innerHTML = "";

			}, 0)
			console.log(this.qrCode, 99999888)
			// _this.getOrderstate() // 生成二维码的时候调用监听支付是否成功的方法
		},

		async getOrderstate() {
			// 创建一个全局的定时器
			this.num++
			console.log(this.num, "订单id====》")
			const res = await getPaymentStatus({ outTradeNo: this.orderId })
			if (res.data.tradeState == 'SUCCESS') {
				clearInterval(this.timer);// 别忘记关闭定时器，否则会一直调这个接口
				this.timer = null
				setTimeout(() => {
					this.$router.push('/myIndex/myOrder?actMyNum=0')
				}, 2000)
				this.$message({
					message: '已完成支付',
					type: 'success'
				});
				return
			}

			// if (!res.data) {
			// 	clearInterval(this.timers) // 别忘记关闭定时器，否则会一直调这个接口
			// }
			// if (num === 5) {
			// 	clearInterval(this.timers)
			// 	// 这里是判断num++到500的情况下用户还没有支付则自动关闭定时器和二维码
			// 	this.$confirm('长时间未付款，订单已自动关闭！', '提示', {
			// 		confirmButtonText: '确定',
			// 		showCancelButton: false,
			// 		type: 'warning'
			// 	}).then(() => {
			// 		// 用户点击了确定按钮时的回调函数
			// 		// 处理删除操作或其他操作
			// 		this.$router.push('/myIndex/myOrder?actMyNum=0')
			// 	})
			// }


		},

	},
	beforeDestroy() {
		if (this.timers) {
			clearInterval(this.timers) // 关闭
		}
		console.log("销毁页面", this.timers)
	}
}
</script>
<style lang="less">
.mainCode {
	min-width: 600px;
	max-width: 800px;
	margin: 10vh auto 0;
	border: 1px solid #f2dede;
	padding-bottom: 50px;

	&-h1 {
		font-size: 16px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-bottom: 1px solid #f2dede;
		box-sizing: border-box;
		margin-bottom: 20px;
		padding: 0 30px 10px;

		/*padding-bottom: 50px;*/
		.money {
			font-size: 28px;
			color: #f60;
		}
	}

	.qrdiv {
		width: 172px;
		box-sizing: border-box;
		padding-top: 12px;
		/*border: 1px solid #f2dede;*/
		box-shadow: 0 0 10px #ddd;
		border-radius: 4px;
		display: flex;
		align-items: center;
		flex-direction: column;

		#qrcode {
			img {
				display: block;
				margin: 0 auto;
			}
		}
	}

	.payMoney {
		text-align: center;
		font-size: 32px;
		color: #ff6600;
		padding-top: 10px;
	}

	.payTxt {
		text-align: center;
		padding-top: 16px;
		line-height: 24px;
		padding-bottom: 12px;
		margin-bottom: 10px;
	}
}
</style>
